import styled from "styled-components";

export const BigSection = styled.div`
  padding: 0;
  margin: 0;
  img {
    width: 9rem;
    height: auto;
    border-radius: 1rem;
    cursor: zoom-in;
  }
  h3{
    /* text-align: center; */
    font-weight: 800;
    text-decoration: underline;
  }
  span{
    color: #7e7e7e;
  }
`;
