import { Button } from "@mui/material";
import styled from "styled-components";

export const Mybutton = styled(Button)`
  /* padding:1rem; */
`;
export const Links = styled.li`
  color: black;
  position: relative;
  margin: auto;
  transition: 1s all;
  a {
    margin: auto;
    transition: 1s all;
    &:hover {
      color: #074575;
    }
    &.active {
      color: #074575;
      /* padding-left: .2rem; */
      .icon{
        color: #1064a4;
      }
      &:after {
        position: absolute;
        content: "";
        bottom: -0.2rem;
        width: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: #358ee1;
        height: 0.4rem;
        border-radius: 1rem;
      }
    }
  }
`;
export const BigSection = styled.div`
  .navbar {
    background-color: blue;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    button {
      background-color: blue;
      border: none;
      cursor: pointer;
    }
  }
  li {
    color: white;
  }
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    /* background-color: white; */
  }

  .nav-menu {
    background-color: blue;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }

  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }

  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }

  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }

  .nav-text a:hover {
    background-color: #1a83ff;
  }

  .nav-menu-items {
    width: 100%;
  }

  .navbar-toggle {
    /* background-color: #060b26; */
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  span {
    margin-left: 16px;
  }
`;
export const TryDiv = styled.div`
  .header {
    display: flex;
    position: fixed;
    flex-direction: column;
    inset: 0 75% 0 0;
    background-color: hsla(0, 40%, 98%, 0.092);
  }

  .header-mobile {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    inset: 0 95% 0 0;
    background-color: hsla(0, 2%, 10%, 0.558);
  }

  nav .ul-item {
    display: flex;
    flex-direction: column;
    margin-block: 0.5rem;
    list-style: none;
  }

  nav .ul-item.oicon a {
    display: none;
  }

  nav .ul-item.oicon li:after {
    content: "";
    position: absolute;
    width: 1%;
    height: 4%;
    background-color: #fff;
    right: 0;
  }

  nav .ul-item li {
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    padding-block: 1.2rem;
    margin-block: 0.1rem;
  }

  nav .ul-item li:hover {
    background-color: rgb(66, 81, 52);
  }

  nav .ul-item li .icon {
    color: rgb(0, 255, 145);
    margin-inline: 1rem;
    font-size: 30px;
  }

  nav .ul-item li a {
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    border-left: 1px solid white;
    padding-left: 2rem;
  }
  .menu-icon {
    margin-inline: 1rem;
  }

  .menu-icon .menu {
    color: rgb(0, 255, 145);
    margin-block: 1rem;
    cursor: pointer;
    font-size: 30px;
  }

  nav .ul-item li:hover {
    transition: 1s ease;
  }

  @media (max-width: 900px) {
    .header-mobile {
      inset: 0 90% 0 0;
      justify-content: center;
    }

    nav .ul-item {
      display: none;
    }

    nav .ul-item.oicon {
      display: flex;
    }
    .menu-icon {
      display: none;
    }

    nav .ul-item li .icon {
      font-size: clamp(1rem, 2vw + 1rem, 4rem);
    }
  }
`;
