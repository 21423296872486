import { configureStore } from "@reduxjs/toolkit";
import authSliceReucer from "../AuthSlice/AuthSlice"
import projectReduce from "../ProjectSlice/ProjectSlice"
import containers from "../Containers/ContainersSlice"
export const store = configureStore({
  reducer: {
    auth: authSliceReucer,
    project:projectReduce,
    containers:containers
  },
});
