import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_user_conainers,
  Get_user_conainers_Filteration,
} from "../../Redux/Containers/ContainersSlice";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Lottie from "lottie-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch, Grid, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { MyButton } from "../../Components/DataTable/tableStyle";
import FileDownload from "js-file-download";
import { Button, Modal } from "@mui/material";
import { Style } from "../../Pages/ContainerWasher/ContainerWasherStyle";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Loader from "../../Components/Loader/9616-animation-loader.json";
import FormFilterByDate from "./FormFilterByDate";
import { set } from "date-fns";
import ShowPhotos from "./ShowPhotos";
import { textAlign } from "@mui/system";
import { Download_Report } from "../../Redux/ProjectSlice/ProjectSlice";
import { Div } from "./userContainerStyle";
const userContainers2 = [
  {
    number: "246810",
    wahsing_type: "steam",
    location: "316545",
    wahsing_date: "20-6-2022",
    start_time: "2.00pm",
    end_time: "2.30pc",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Container Number ",
  },
  {
    id: "Washing_type ",
    numeric: true,
    disablePadding: false,
    label: "Wahsing Type",
  },
  {
    id: "Location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "wash_date",
    numeric: true,
    disablePadding: false,
    label: "wahsing Date",
  },
  {
    id: "start_time",
    numeric: true,
    disablePadding: false,
    label: "Start Time",
  },
  {
    id: "end_time",
    numeric: true,
    disablePadding: false,
    label: "End Time",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const statusGetCont = useSelector((state) => state?.containers.status);
  // console.log("statusGetCont", statusGetCont);
  return (
    <>
      {statusGetCont === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} md={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {statusGetCont === "success" && (
        <>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ fontWeigth: "bold", fontSize: "1.05rem" }}
                  key={headCell.id}
                  // align={headCell.numeric ? "center" : "left"}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </>
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const statusGetCont = useSelector((state) => state?.containers.status);
  return (
    <>
      {statusGetCont === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} amd={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {statusGetCont === "success" && (
        <>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {numSelected > 0 ? null : ( // </Typography> //   {numSelected} selected // > //   component="div" //   variant="subtitle1" //   color="inherit" //   sx={{ flex: '1 1 100%' }} // <Typography
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Containers
              </Typography>
            )}

            {numSelected > 0
              ? // <Tooltip title="Delete">
                //   <IconButton>
                //     <DeleteIcon />
                //   </IconButton>
                // </Tooltip>
                null
              : // <Tooltip title="Filter list">
                //   <IconButton>
                //     <FilterListIcon />
                //   </IconButton>
                // </Tooltip>
                null}
          </Toolbar>
        </>
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const UserContainer = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [normal, setNoraml] = React.useState(true);
  const [selectedContainer, setSelectedContainer] = React.useState();
  // console.log("selectedContainer", selectedContainer);
  const dispatch = useDispatch();
  const { id } = useParams();

  const userContainers =
    useSelector((state) => state.containers.userContainers.data) || [];

  const status = useSelector((state) => state?.containers.create_status);
  const statusGetCont = useSelector((state) => state?.containers.status);
  const lastPage = useSelector(
    (state) => state?.containers?.userContainers?.last_page
  );
  const total = useSelector(
    (state) => state?.containers?.userContainers?.total
  );
  const downLoadFile = useSelector((state) => state.project.report);
  const downLoadFileStatus = useSelector((state) => state.project.status);
  console.log("downLoadFile", downLoadFile);
  const navigate = useNavigate();
  // console.log("userContainers", userContainers);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [date, setDate] = React.useState({
    // date: new Date().toISOString().split("T")[0],
    from:"",
    to: "",
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = userContainers.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - userContainers.length)
      : 0;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log("Page Number", page);
  const [number, setNumber] = React.useState(1);
  let data = {
    page: number,
  };
  const next = async () => {
    await setNumber((pre) => ++pre);
  };
  const back = async () => {
    await setNumber((pre) => --pre);
  };

  const [search, setSearch] = React.useState("");
  let filterdate = {
    page: number,
    from: date.from,
    to: date.to,
    search: search,
  };
  console.log("filterdate", filterdate);

  React.useEffect(() => {
    if (normal) {
      dispatch(Get_user_conainers_Filteration(data));
    } else {
      // console.log("filterdate", filterdate);
      // console.log("Condition is Correct");
      // dispatch(Get_user_conainers_Filteration(filterdate));
    }
  }, [number, normal]);

  React.useEffect(() => {
    if (normal !== true || search == "") {
      console.log("filterdate", filterdate);
      dispatch(Get_user_conainers_Filteration(filterdate));
    }
  }, [number, normal, search]);
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) dispatch(Get_user_conainers_Filteration(filterdate));
    }, 550);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  React.useEffect(() => {
    if (number < 0 || number === 0) {
      setNumber(1);
    }
  }, [number]);

  React.useEffect(() => {
    if (normal === false) {
      setNumber(1);
    }
  }, [normal]);
  const initialState = {
    from: "",
    to: "",
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setNoraml(false);
    setNumber(1);
    filterdate.page = 1;
    console.log("filterdate in Submit", filterdate);
    dispatch(Get_user_conainers_Filteration(filterdate));
  };

  const normalAgain = () => {
    setNoraml(true);
    setDate({ ...initialState });
  };
  const { location } = useLocation();
  let downloadDate = {
    from: date.from,
    to: date.to,
  };
  const download = () => {
    dispatch(Download_Report(downloadDate));
  };
  console.log("Rendered");
  return (
    <Div>
      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="div">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Grid
            container
            columns={12}
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={6} xs={12}>
              <div className="div">
                <form onSubmit={handleSubmit}>
                  <Grid container columns={12} direction="row" spacing={2}   alignItems="center">
                    <Grid item lg={4} md={4} xs={4}>
                      <TextField
                        type="date"
                        required
                        fullWidth
                        value={date?.from}
                        name="wash_date"
                        label="From date"
                        timezone="[[timezone]]"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(newDate) => {
                          setDate({
                            ...date,
                            from: newDate.target.value,
                          });
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4}>
                      <TextField
                        type="date"
                        required
                        fullWidth
                        value={date?.to}
                        name="wash_date"
                        label="To date"
                        timezone="[[timezone]]"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(newDate) => {
                          setDate({
                            ...date,
                            to: newDate.target.value,
                          });
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4}>
                      {" "}
                      <Button type="submit">Submit <small style={{textTransform:"capitalize"}}>(Filter by Date)</small></Button>
                      <Button type="button" onClick={normalAgain}>
                        Reset Filteration
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="div">
                <Button onClick={download}>Download Report</Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <div className="div" style={{ textAlign: "left" }}>
            <h3>
              Page number <span style={{ color: "#1983da" }}>{number}</span>
              <span style={{ margin: "0 2rem" }}>
                Total count : <span style={{ color: "#1983da" }}>{total}</span>
              </span>
            </h3>
            {number === 1 ? (
              <>First Page</>
            ) : (
              <>
                {" "}
                <MyButton
                  endIcon={<NavigateBeforeIcon></NavigateBeforeIcon>}
                  onClick={back}
                >
                  Previous Page{" "}
                </MyButton>
              </>
            )}

            {lastPage === number ? (
              <>No more Pages</>
            ) : (
              <>
                {" "}
                <MyButton
                  endIcon={<NavigateNextIcon></NavigateNextIcon>}
                  onClick={next}
                >
                  Next Page
                </MyButton>
              </>
            )}
          </div>
        </Grid>
      </Grid>

      {statusGetCont === "loading" ||
        (downLoadFileStatus === "loading" && (
          <>
            <Grid container columns={12}>
              <Grid item lg={12} md={12}>
                <Lottie
                  loop={true}
                  animationData={Loader}
                  style={{
                    width: "200px",
                    height: "200px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                  }}
                />
              </Grid>
            </Grid>
          </>
        ))}
      {/* <Button onClick={click}>Get more Data</Button> */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={userContainers.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(userContainers, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((userContainers, index) => {
                    const isItemSelected = isSelected(userContainers.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, userContainers.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={userContainers.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> */}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          {userContainers.number}
                        </TableCell>
                        <TableCell align="left">
                          {userContainers?.washing_type?.name}
                        </TableCell>
                        <TableCell align="left">
                          {userContainers.location}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(
                            userContainers.wash_date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {userContainers.start_time}
                        </TableCell>
                        <TableCell align="left">
                          {userContainers.end_time}
                        </TableCell>
                        <TableCell align="left">
                          {userContainers.status}
                        </TableCell>
                        <TableCell align="left">
                          {/* <NavLink
                            state={{
                              containerData: userContainers,
                              number: number,
                            }}
                            to={`/home/user-container/photos/${userContainers.id}`}
                          > */}{" "}
                          <MyButton
                            onClick={() => {
                              setSelectedContainer(userContainers);
                              handleOpen();
                            }}
                          >
                            View photos
                          </MyButton>
                          {/* </NavLink> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userContainers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Container
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ShowPhotos container={selectedContainer}></ShowPhotos>
          </Typography>
        </Box>
      </Modal>
      <Grid item lg={6} md={6}>
        <div className="div" style={{ textAlign: "left" }}>
          <h3>
            Page number <span style={{ color: "#1983da" }}>{number}</span>
            <span style={{ margin: "0 2rem" }}>
              Total count : <span style={{ color: "#1983da" }}>{total}</span>
            </span>
          </h3>
          {number === 1 ? (
            <>First Page</>
          ) : (
            <>
              {" "}
              <MyButton
                endIcon={<NavigateBeforeIcon></NavigateBeforeIcon>}
                onClick={back}
              >
                Previous Page{" "}
              </MyButton>
            </>
          )}

          {lastPage === number ? (
            <>No more Pages</>
          ) : (
            <>
              {" "}
              <MyButton
                endIcon={<NavigateNextIcon></NavigateNextIcon>}
                onClick={next}
              >
                Next Page
              </MyButton>
            </>
          )}
        </div>
      </Grid>
    </Div>
  );
};

export default UserContainer;
