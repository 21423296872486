import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get_statistics } from "../../Redux/ProjectSlice/ProjectSlice";
import { BigSection, Div } from "./statisticsStyle";
import Lottie from "lottie-react";
import loader from "../../Components/Loader/9616-animation-loader.json";
const UserStatistics = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.project?.statistics?.data) || {};
  const status = useSelector((state) => state?.project.status);
  console.log("status", status);
  let arr = Object.keys(data).map((key) => data[key]);
  console.log("data", arr);
  console.log("data", data);
  React.useEffect(() => {
    dispatch(Get_statistics());
  }, []);
  return (
    <BigSection>
      <h2 style={{ textAlign: "center" }}>statistics</h2>
      <Grid
        container
        // columns={12}
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {status !== "loading" && (
          <>
            {arr.map((e) => {
              return (
                <Grid item lg={3} md={4} sm={12} xs={12}>
                  <Div>
                    <p className="header">
                      {e.title}
                      <p>{e.value}</p>
                    </p>
                  </Div>
                </Grid>
              );
            })}
          </>
        )}
        {status === "loading" && (
          <>
            <Lottie
              style={{
                width: "200px",
                height: "200px",
                position: "absolute",
                // transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
              }}
              loop={true}
              animationData={loader}
            />
          </>
        )}

        {/* <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div>
            <p className="header">
              {data?.containers_approved_this_month.title}
              <p>{data?.containers_approved_this_month.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div red>
            <p className="header">
              {data?.containers_rejected_this_month.title}
              <p> {data?.containers_rejected_this_month.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div>
            <p className="header">
              {data?.today_wash.title}
              <p> {data?.today_wash.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div red>
            <p className="header">
              {data?.total_containers_approved.title}
              <p> {data?.total_containers_approved.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div>
            <p className="header">
              {data?.total_containers_rejected.title}
              <p> {data?.total_containers_rejected.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div red>
            <p className="header">
              {data?.total_non_wash.title}
              <p> {data?.total_non_wash.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div>
            <p className="header">
              {data?.total_wash.title}
              <p> {data?.total_wash.value}</p>
            </p>
          </Div>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Div red>
            <p className="header">
              {data?.total_wash_this_month.title}
              <p>{data?.total_wash_this_month.value}</p>
            </p>
          </Div>
        </Grid> */}
      </Grid>
    </BigSection>
  );
};

export default UserStatistics;
