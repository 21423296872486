import {
  Grid,
  TextField,
  FormControl,
  Modal,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React from "react";
import NavBar from "../../Components/NavBar/NavBar";
import ShowImages from "../../Components/ShowImages/ShowImages";
import UploadButton from "../../Layouts/UploadButton";
import { BigSection, MyButton, Style } from "./ContainerWasherStyle";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { Bigsection } from "../Login/LoginStyle";
import EnhancedTable from "../../Components/DataTable/EnhancedTable";
import AddConainerForm from "../../Components/AddContainerForm/AddConainerForm";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Search_Container } from "../../Redux/Containers/ContainersSlice";
const ContainerWasher = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const periods = [
    { name: "Last seven days", value: "last_seven_days" },
    { name: "From to", value: "from_to" },
    { name: "Month", value: "month" },
    { name: "Year", value: "last_seven_days" },
    { name: "All Time", value: "all_time" },
  ];

  return (
    <>
      <NavBar></NavBar>
      <div style={{ textAlign: "right" }}>
        <MyButton onClick={handleOpen}>Add Container</MyButton>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <AddConainerForm handleClose={handleClose}></AddConainerForm>
          </Typography>
        </Box>
      </Modal>

      <Bigsection>
        <Grid container columns={12}>
          <Grid item lg={12} md={12}>
            {" "}
            <Link to="/home/projects">
              {" "}
              <Button>Go back to Project</Button>
            </Link>
          </Grid>
          {/* <Grid item lg={12} md={12}>
            <FormControl fullWidth required={true}>
              <InputLabel id="demo-simple-select-label">
                Search Period
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Search Period"
                required={true}
                // value={value.utility_provider_id}
                // onChange={(e) =>
                //   setValue({
                //     ...value,
                //     utility_provider_id: e.target.value,
                //   })
                // }
              >
                {periods?.map((e) => (
                  <MenuItem key={e.value} value={e.value}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
      
        </Grid>

        <EnhancedTable></EnhancedTable>
      </Bigsection>
    </>
  );
};

export default ContainerWasher;
