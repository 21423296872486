import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
// import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authentication } from "../../Redux/AuthSlice/AuthSlice";
import { useNavigate } from "react-router-dom";
import { CssTextField, Slogan } from "./LoginFormStyle";

const LoginForm = () => {
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(5, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state?.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(authentication(values));
      await navigate("/");
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item lg={12} md={12}>
            <Slogan>Welcome Home </Slogan>
          </Grid>
          <Grid item mb={2} xs={12}>
            {/* <InputLabel htmlFor="component-simple">Email</InputLabel> */}
            <CssTextField
              fullWidth
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item mb={2} xs={12}>
            {/* <InputLabel htmlFor="component-simple">password</InputLabel> */}
            <CssTextField
              fullWidth
              id="outlined-basic"
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
        </Grid>
        <PrimaryButton
          fullWidth
          type="submit"
          endIcon={
            status === "loading" ? (
              <CircularProgress size={20} thickness={8} />
            ) : null
          }
        >
          Sign in
        </PrimaryButton>
      </form>
    </>
  );
};

export default LoginForm;
