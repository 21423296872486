import { Grid } from "@mui/material";
import React from "react";
import LoginForm from "../../Components/Login/loginForm";
import Lottie from "lottie-react";
import lottieLogin from "../../Components/Login/LoginLottie.json";
import { Bigsection } from "./LoginStyle";
const Login = () => {
  return (
    <>
    <Bigsection>
      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
          <Grid item lg={6} md={6} sm={12}>
            <Lottie loop={true} animationData={lottieLogin} />
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <LoginForm></LoginForm>
          </Grid>
      </Grid>
      </Bigsection>
    </>
  );
};

export default Login;
