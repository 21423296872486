import { Button } from "@mui/material";
import styled from "styled-components";

export const BigSection = styled.section`
  margin: 1rem 2rem;
  h3 {
    text-align: center;
  }
  .uploadimage {
    width: 100;
    box-shadow: 0px 0px 19px 5px rgba(209, 209, 209, 0.94);
    padding: 1rem;
  }
  .showImage {
    box-shadow: 0px 0px 19px 5px rgba(209, 209, 209, 0.94);
  }
`;
export const MyButton = styled(Button)`
  width: 100%;
  height: 100%;
  &:hover {
    background-color: white !important;
    color: black;
  }
`;
export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 0,
  p: 4,
  backgroundColor: "white",
  overflow: "scroll",
  height: "60%",
  display: "block",
};
