import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { MyButton, Style, Direct } from "./TableStyle";
import {
  ADD_PROJECT,
  Get_PROJECT,
} from "../../Redux/ProjectSlice/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddProject from "../../Pages/AddProject/AddProject";
import Lottie from "lottie-react";
import Loader from "../../Components/Loader/9616-animation-loader.json";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import { DeleteContaienr } from "../../Redux/Containers/ContainersSlice";
import EditProject from "../../Pages/AddProject/EditProject";

const DashBoard = () => {
  const dispatch = useDispatch();
  const [number, setNumber] = React.useState(1);

  const { project } = useSelector((state) => state);
  const allProjects = project?.all_prjects?.data?.data || [];

  const status = useSelector((state) => state.project.getProjectStatus);
  const lastPage = project?.all_prjects?.data?.last_page;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Edit
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const [selectedProject, setSelectedProject] = React.useState();
  console.log("selectedProject", selectedProject);
  const next = async () => {
    await setNumber((pre) => ++pre);
    // await dispatch(Get_PROJECT(number));
  };
  const back = async () => {
    await setNumber((pre) => --pre);
    // await dispatch(Get_PROJECT(...number));
  };
  React.useEffect(() => {
    dispatch(Get_PROJECT(number));
  }, [number]);

  React.useEffect(() => {
    if (number < 0 || number === 0) {
      setNumber(1);
    }
  }, [number]);

  return (
    <>
      <h3>Page number {number}</h3>
      {number === 1 ? (
        <MyButton>Previous Page </MyButton>
      ) : (
        <>
          {" "}
          <MyButton onClick={back}>Previous Page </MyButton>
        </>
      )}

      {lastPage === number ? (
        <>No more Pages</>
      ) : (
        <>
          {" "}
          <MyButton onClick={next}>Next Page</MyButton>
        </>
      )}
      {status === "success" && (
        <>
          <div style={{ textAlign: "right" }}>
            <MyButton onClick={handleOpen}>Add project</MyButton>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Style}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <AddProject handleClose={handleClose}></AddProject>
              </Typography>
            </Box>
          </Modal>
          <div style={{ height: 400, width: "100%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allProjects?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" align="center">
                        <Link to={`${row.id}`}>
                          <Direct> {row.id}</Direct>
                        </Link>
                      </TableCell>
                      <TableCell component="th" align="center">
                        <Link to={`${row.id}`}>
                          <Direct> {row.project_name}</Direct>{" "}
                        </Link>
                      </TableCell>
                      <TableCell align="center">{row.description}</TableCell>
                      <TableCell component="th" align="center">
                        {" "}
                        <Link to={`/containers/${row.id}`}>
                          <MyButton>containers</MyButton>
                        </Link>
                        <MyButton
                          onClick={() => {
                            setSelectedProject(row);
                            handleOpenEdit();
                          }}
                        >
                          {" "}
                          <EditIcon />
                        </MyButton>
                        <MyButton>
                          {" "}
                          <DeleteForeverIcon />
                        </MyButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <h3>Page number {number}</h3>
            {number === 1 ? (
              <MyButton>Previous Page </MyButton>
            ) : (
              <>
                {" "}
                <MyButton onClick={back}>Previous Page </MyButton>
              </>
            )}

            {lastPage === number ? (
              <>No more Pages</>
            ) : (
              <>
                {" "}
                <MyButton onClick={next}>Next Page</MyButton>
              </>
            )}
          </div>
          {/* Edit */}
          <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                Edit Project
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                align="center"
              >
               <EditProject project={selectedProject} close={handleCloseEdit}></EditProject>
              </Typography>
            </Box>
          </Modal>
        </>
      )}
      {status === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} md={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DashBoard;
