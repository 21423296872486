import styled from "styled-components";
import { Button } from "@mui/material";
export const PrimaryButton = styled(Button)`
  && {
    background-color: #3B9AE1;
    color: black;
    font-size: 1.2rem;
    margin: 1.5rem 0;
    box-shadow: 0 1px 5px;
    padding: 0.8rem;
    border-radius: 1rem;
    box-shadow: 0 8px 16px 0 rgb(39 141 246 / 30%);
    transition: all 0.3s;
    font-weight: 600;
    letter-spacing: 5px;
    &:hover {
      transform: translateY(-5px);
      background-color: black;
      color: #3B9AE1;
    }
  }
`;
export const NavBarButton = styled(Button)`
  && {
    justify-content: flex-start;
    padding: 0.9rem;
    border-radius: var(--border-radius);
    text-transform: capitalize;
    color: var(--gray);
    svg {
      margin-right: 0.8rem;
    }
    &:hover {
      color: #fff;
    }
  }
`;
