import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Lottie from "lottie-react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch, Grid, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { MyButton, style } from "./tableStyle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Get_Containers,
  GET_CONTAINERS,
  GET_WashingTypes,
  Add_Image_Containers,
  DeleteContaienr,
  Search_Container,
} from "../../Redux/Containers/ContainersSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "@mui/material";
import { Style } from "../../Pages/ContainerWasher/ContainerWasherStyle";
import AddcontainerPhotos from "../AddContainerForm/AddcontainerPhotos";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Components/Loader/9616-animation-loader.json";
import axios from "axios";
import EditContainerForm from "../AddContainerForm/EditContainerForm";

const newRows2 = [
  {
    number: "246810",
    wahsing_type: "steam",
    location: "316545",
    wahsing_date: "20-6-2022",
    start_time: "2.00pm",
    end_time: "2.30pc",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "number",
    // numeric: false,
    disablePadding: true,
    label: "Container Number ",
  },
  {
    id: "Washing_type ",
    numeric: true,
    disablePadding: false,
    label: "Wahsing Type",
  },
  {
    id: "Location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "wash_date",
    numeric: true,
    disablePadding: false,
    label: "wahsing Date",
  },
  {
    id: "start_time",
    numeric: true,
    disablePadding: false,
    label: "Start Time",
  },
  {
    id: "end_time",
    numeric: true,
    disablePadding: false,
    label: "End Time",
  },
  {
    id: "media_count",
    numeric: true,
    disablePadding: false,
    label: "Media Count",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const statusGetCont = useSelector((state) => state?.containers.status);
  return (
    <>
      {statusGetCont === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} md={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {statusGetCont === "success" && (
        <>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </>
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const statusGetCont = useSelector((state) => state?.containers.status);
  return (
    <>
      {statusGetCont === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} md={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {statusGetCont === "success" && (
        <>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {numSelected > 0 ? null : ( // </Typography> //   {numSelected} selected // > //   component="div" //   variant="subtitle1" //   color="inherit" //   sx={{ flex: '1 1 100%' }} // <Typography
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Containers
              </Typography>
            )}

            {numSelected > 0
              ? // <Tooltip title="Delete">
                //   <IconButton>
                //     <DeleteIcon />
                //   </IconButton>
                // </Tooltip>
                null
              : // <Tooltip title="Filter list">
                //   <IconButton>
                //     <FilterListIcon />
                //   </IconButton>
                // </Tooltip>
                null}
          </Toolbar>
        </>
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(GET_WashingTypes());
  }, []);
  const washingTypes =
    useSelector((state) => state?.containers?.washing_types) || [];
  const newRows =
    useSelector(
      (state) =>
        state?.containers?.all_containers?.data ||
        state.containers.all_containers
    ) || [];
  const status = useSelector((state) => state?.containers.create_status);
  const statusGetCont = useSelector((state) => state?.containers.status);
  const lastPage = useSelector(
    (state) => state?.containers?.all_containers?.last_page
  );
  const total = useSelector(
    (state) => state?.containers?.all_containers?.total
  );
  const what = useSelector((state) => state.containers.all_containers);
  console.log("what", what);
  const [number, setNumber] = React.useState(1);
  let data = {
    page: number,
    id: id,
  };
  const next = async () => {
    await setNumber((pre) => ++pre);
  };
  const back = async () => {
    await setNumber((pre) => --pre);
  };
  React.useEffect(() => {
    if (search === "") dispatch(Get_Containers(data));
  }, [number, search]);

  React.useEffect(() => {
    if (number < 0 || number === 0) {
      setNumber(1);
    }
  }, [number]);

  console.log("newRows", newRows);

  console.log("newRows", newRows);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = newRows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  let searchKeys = {
    search: search,
    id: id,
  };
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) dispatch(Search_Container(searchKeys));
    }, 550);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newRows.length) : 0;

  // Delete Modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [selectedContainer, setSelectedContainer] = React.useState();
  const deleteContainer = async () => {
    await dispatch(DeleteContaienr(selectedContainer.id));
    await handleCloseDelete();
    await dispatch(Get_Containers(data));
  };
  // Edit Modal
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />
      </Grid>
      <h3>
        Page number <span style={{ color: "#1983da" }}>{number}</span>
        <span style={{ margin: "0 2rem" }}>
          Total count : <span style={{ color: "#1983da" }}>{total}</span>
        </span>
      </h3>
      {number === 1 ? (
        <MyButton>Previous Page </MyButton>
      ) : (
        <>
          {" "}
          <MyButton onClick={back}>Previous Page </MyButton>
        </>
      )}

      {lastPage === number ? (
        <>No more Pages</>
      ) : (
        <>
          {" "}
          <MyButton onClick={next}>Next Page</MyButton>
        </>
      )}
      {/* <button onClick={click}>get more data</button> */}
      {statusGetCont === "loading" && (
        <>
          <Grid container columns={12}>
            <Grid item lg={12} md={12}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750, textAlign: "center" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={newRows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(newRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((newRows, index) => {
                    const isItemSelected = isSelected(newRows.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, newRows.name)}
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={newRows.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          {newRows.number}
                        </TableCell>
                        <TableCell align="center">
                          {newRows?.washing_type?.name}
                        </TableCell>
                        <TableCell align="right">{newRows.location}</TableCell>
                        <TableCell align="right">
                          {new Date(newRows.wash_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {newRows.start_time}
                        </TableCell>
                        <TableCell align="right">{newRows.end_time}</TableCell>
                        <TableCell align="center">
                          {newRows.media_count}
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            state={{ data: newRows }}
                            to={`/home/containers/add-photos/${newRows.id}`}
                          >
                            {" "}
                            <MyButton>
                              <AddPhotoAlternateIcon></AddPhotoAlternateIcon>
                            </MyButton>
                          </Link>

                          <MyButton
                            onClick={() => {
                              setSelectedContainer(newRows);
                              handleOpenEdit();
                            }}
                            style={{ background: "none" }}
                          >
                            <EditIcon
                              style={{ color: "red", color: "#5499C7 " }}
                            ></EditIcon>
                          </MyButton>
                          <MyButton
                            onClick={() => {
                              setSelectedContainer(newRows);
                              handleOpenDelete();
                            }}
                            style={{ background: "none" }}
                          >
                            <DeleteIcon style={{ color: "red" }}></DeleteIcon>
                          </MyButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={newRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>

        <h3>Page number {number}</h3>
        {number === 1 ? (
          <MyButton>Previous Page </MyButton>
        ) : (
          <>
            {" "}
            <MyButton onClick={back}>Previous Page </MyButton>
          </>
        )}

        {lastPage === number ? (
          <>No more Pages</>
        ) : (
          <>
            {" "}
            <MyButton onClick={next}>Next Page</MyButton>
          </>
        )}
      </Box>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Are you sure Want to delete This container{" "}
            <span style={{ color: "#1983da" }}>
              {selectedContainer?.number}
            </span>
            ?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <Button onClick={deleteContainer} style={{ color: "red" }}>
              Yes
            </Button>
            <Button onClick={handleCloseDelete}>No</Button>
          </Typography>
        </Box>
      </Modal>
      {/* Edit */}
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Edit Container
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <EditContainerForm
              close={handleCloseEdit}
              container={selectedContainer}
            ></EditContainerForm>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
