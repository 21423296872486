import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { GlobalStyle } from "../src/Style/GlobalStyle";
import Login from "./Pages/Login/Login";
import LeftSideBar from "./Components/LeftSideBar/LeftSideBar";
import DashBoard from "./Components/DashboardTable/DashBoard";
import ContainerWasher from "./Pages/ContainerWasher/ContainerWasher";
import { useSelector } from "react-redux";
import AddProject from "./Pages/AddProject/AddProject";
import Test from "./Components/Test";
import ShowContainers from "./Pages/showContainers/ShowContainers";
import EnhancedTable from "./Components/DataTable/EnhancedTable";
import AddConainerForm from "./Components/AddContainerForm/AddConainerForm";
import AddcontainerPhotos from "./Components/AddContainerForm/AddcontainerPhotos";
import UserNavBar from "./Components/UserComponents/NavBar/UserNavBar";
import UserContainer from "./Pages/UserPages/userContainer";
import ShowPhotos from "./Pages/UserPages/ShowPhotos";
import SinlgeProject from "./Components/DashboardTable/SinlgeProject";
import Welcome from "./Components/Welcome";
import NewLeftSideBar from "./Components/LeftSideBar/newLeftSideBar";
import TestLeftSliderBar from "./Components/LeftSideBar/TestLeftSliderBar";
import UserStatistics from "./Components/UserComponents/statistics";
function App() {
  const isAutherized = useSelector((state) => state.auth?.data);
  const role = isAutherized?.user?.type || isAutherized?.data?.user?.type;
  const location = useLocation();
  const params = useParams();
  const wholeUrl = window.location.href.includes("verified=1");
  return (
    <>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {wholeUrl ? (
        <>
          <Routes>
            <Route path="*" element={<Navigate to="/home" />}></Route>
            <Route path="/home" element={<Welcome></Welcome>}></Route>
          </Routes>
        </>
      ) : (
        <>
          {isAutherized ? (
            <>
              {role === "admin" ? (
                <>
                  {/* Admin Components */}
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/home/projects" />}
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to="/home/projects" />}
                    ></Route>
                    <Route path="/home" element={<LeftSideBar></LeftSideBar>}>
                      <Route
                        path="projects"
                        element={<DashBoard></DashBoard>}
                      ></Route>
                      <Route
                        path="projects/:id"
                        element={<SinlgeProject></SinlgeProject>}
                      ></Route>
                      <Route
                        path="rejected-report"
                        element={<h1>rejected</h1>}
                      ></Route>
                      <Route
                        path="Statistics"
                        element={<h1>Statistics</h1>}
                      ></Route>
                      <Route
                        path="containers"
                        element={<EnhancedTable></EnhancedTable>}
                      ></Route>
                      <Route
                        path="containers/add-photos/:id"
                        element={<AddcontainerPhotos></AddcontainerPhotos>}
                      ></Route>
                    </Route>
                    <Route
                      path="containers/:id"
                      element={<ContainerWasher></ContainerWasher>}
                    ></Route>
                  </Routes>
                </>
              ) : (
                <>
                  {/* User Componetns */}
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/home/user-container" />}
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to="/home/user-container" />}
                    ></Route>
                    <Route path="/home" element={<UserNavBar></UserNavBar>}>
                      <Route
                        path="user-container"
                        element={<UserContainer></UserContainer>}
                      ></Route>
                      <Route
                        path="user-container/photos/:id"
                        element={<ShowPhotos></ShowPhotos>}
                      ></Route>
                      <Route
                        path="User-Statistics"
                        element={<UserStatistics></UserStatistics>}
                      ></Route>
                    </Route>
                  </Routes>
                </>
              )}
            </>
          ) : (
            <>
              <Routes>
                <Route path="*" element={<Login />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </>
          )}
        </>
      )}
    </>
  );
}

export default App;
