import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { MyButton } from "../../Pages/ContainerWasher/ContainerWasherStyle";
import {
  Add_Image_Containers,
  get_admin_conainers_photos,
  Upload_multi_images,
} from "../../Redux/Containers/ContainersSlice";
import ShowImages from "../ShowImages/ShowImages";
import { Div } from "./AddContainerPhotosStyle";
const AddcontainerPhotos = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = React.useState();
  const containerData = location.state?.data;
  console.log("containerData", containerData);

  const [imageOneBefore, setImageOneBefore] = React.useState({
    position: "position_1",
    condition: "before",
    image: "",
  });
  React.useEffect(() => {
    dispatch(get_admin_conainers_photos(id));
  }, []);
  const handleChange = (e) => {
    const { name, files } = e.target;
    setData({ ...data, [name]: files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("position", imageOneBefore?.position);
    form.append("position_2", imageOneBefore.condition);
    form.append("position_3", imageOneBefore.image);
    let sendData = {
      form,
      id,
    };
    dispatch(Add_Image_Containers(sendData));
  };
  let images = {
    imageOneBefore,
  };
  const status = useSelector((state) => state.containers.add_image_status);
  const status_photos = useSelector((state) => state.containers.status);
  const photos = useSelector((state) => state.containers.userContainerPhotos);
  const multiphotos=useSelector((state)=>state.containers.userContainersPhotos)
  console.log("status", status);
  const isEmpty = Object?.keys(photos);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const initialStatus = [];
  const MAX_COUNT = 6;
  const [fileLimit, setFileLimit] = React.useState(false);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  console.log("Images", uploadedFiles);

  // const uploadImages=()=>{
  //   const form = new FormData();
  //   uploadedFiles.map((e)=>{
  //     form.append("images[]", e);
  //   })
  //    form.append("id", id);
  //    if(uploadedFiles)
  //    dispatch(Upload_multi_images(form));
  // }
  // const firstUpdate = React.useRef(true);
  // React.useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }

  //   console.log("componentDidUpdateFunction");
  // });
  const countHasChangedRef = React.useRef(false);

  React.useEffect(() => {
    const form = new FormData();
    uploadedFiles.map((e) => {
      form.append("images[]", e);
    });
    // form.append("id", containerData.id );
    if (!countHasChangedRef.current) return;
    let sendData={
      form:form,
      id:containerData.id
    }
    dispatch(Upload_multi_images(sendData));
  }, [uploadedFiles]);
  
  return (
    <Div>
      <Grid container columns={12} spacing={2}>
        <Grid item lg={12} md={12} sm={12}>
          <Button onClick={() => navigate(-1)}>go back</Button>
        </Grid>
        <Grid container columns={12} className="containerInfo">
          <Grid lg={12}>
            {" "}
            <p style={{ fontSize: "1.5rem" }}>Container Info</p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container location: <span>{containerData?.location} </span>{" "}
            </p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container number: <span> {containerData?.number} </span>{" "}
            </p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container status: <span>{containerData?.status} </span>{" "}
            </p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container wash_date: <span> {containerData?.wash_date} </span>{" "}
            </p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container washing_type:{" "}
              <span> {containerData?.washing_type?.name} </span>{" "}
            </p>
          </Grid>
          <Grid item lg={6}>
            <p>
              Container end_time: <span>{containerData.end_time} </span>{" "}
            </p>
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          {" "}
          <h4 style={{ textAlign: "center" }}>Add container Photos</h4>
          <h3>
            {" "}
            {status || status_photos === `loading` ? (
              <CircularProgress size={50} thickness={8} color={"warning"} />
            ) : null}
          </h3>
          <h3>First Photo</h3>
          {/* <form onSubmit={handleSubmit}> */}
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                Before
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="first_image_before"
                  // value={images.first_image_before}
                  // onChange={handleChange}
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", imageOneBefore?.position);
                    await form.append("condition", imageOneBefore.condition);
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                After
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="first_image_after"
                  // value={images.first_image_after}
                  // onChange={handleChange}
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", "position_1");
                    await form.append("condition", "after");
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>
            </Grid>
          </Grid>
          <hr />
          {/* Second Image */}
          <h3>Second Photo</h3>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                Before
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="second_image_before"
                  // value={images.second_image_before}
                  // onChange={handleChange}
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", "position_2");
                    await form.append("condition", "before");
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                After
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="second_image_after"
                  // value={images.second_image_after}
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", "position_2");
                    await form.append("condition", "after");
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>{" "}
            </Grid>
          </Grid>
          <hr />
          {/* Third Image */}
          <h3>Third Photo</h3>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                Before
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="third_image_before"
                  // value={images.third_image_before}
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", "position_3");
                    await form.append("condition", "before");
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>{" "}
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              {" "}
              <MyButton variant="contained" component="label">
                After
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="third_image_after"
                  onChange={async (e) => {
                    setImageOneBefore({
                      ...imageOneBefore,
                      image: e.target.files[0],
                    });
                    const form = new FormData();
                    await form.append("position", "position_3");
                    await form.append("condition", "after");
                    await form.append("image", e.target.files[0]);
                    let sendData = {
                      form,
                      id,
                    };
                    await dispatch(Add_Image_Containers(sendData));
                    await dispatch(get_admin_conainers_photos(id));
                  }}
                />
              </MyButton>{" "}
            </Grid>

            <Grid item xs={12}>
              <h3>Multi Images</h3>
              <hr />{" "}
              <MyButton
                variant="contained"
                component="label"
                style={{ height: "3rem" }}
              >
                Upload multi images
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  name="third_image_after"
                  // value={images.third_image_after}
                  multiple
                  onChange={(e) => {
                    handleFileEvent(e);
                    countHasChangedRef.current = true;
                  }}
                />
              </MyButton>{" "}
            </Grid>
          </Grid>
          {/* <Grid item lg={12} md={12}>
            <PrimaryButton style={{ width: "100%" }} type="submit">
              Submit
            </PrimaryButton>
          </Grid> */}
          {/* </form> */}
        </Grid>

        {/* Right Side */}
        <Grid item lg={6} md={12} sm={12}>
          {/* {photos ?: 
            <>
              <div>
                <ShowImages images={photos}></ShowImages>
              </div>
            </>:<>
            <h1>NO photos</h1>
            </>
          } */}
          {photos.length > 0 || isEmpty.length !== 0 ? (
            <>
              <div>
                <ShowImages images={photos}></ShowImages>
              </div>
            </>
          ) : (
            <>
              <h2 style={{ textAlign: "center" }}>No photos</h2>
            </>
          )}
        </Grid>
      </Grid>
    </Div>
  );
};

export default AddcontainerPhotos;
