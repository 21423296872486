import styled from "styled-components";

export const Div = styled.div`
  .div {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.27);
    padding: .9rem .5rem .9rem .5rem;
    text-align: center;
    margin:.5rem 0;
  }
`;
