import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link, useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/AuthSlice/AuthSlice";
import styled from "styled-components";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};
const MyButton = styled.button``;
const Welcome = () => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [number, setNumber] = React.useState(10000);
  const navigate = useNavigate();
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(logout());
      navigate("/login");
    }, number);

    return () => clearTimeout(delayDebounceFn);
  }, []);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <CheckCircleOutlineIcon
              style={{ color: "green", fontSize: "2rem" }}
            ></CheckCircleOutlineIcon>
            <p>Your Account Has Been Acrivated </p>
          </Typography>
          <Typography
            id="modal-modal-description"
            align="center"
            sx={{ mt: 2 }}
          >
            <p>
              You will be redirect in 10 seconds or you can click{" "}
              <Link to="/login" style={{ color: "blue" }}>
                <Button
                  style={{ border: "none", backGround: "white" }}
                  onClick={(e) => {
                    dispatch(logout());
                  }}
                >
                  Redirect
                </Button>
              </Link>
            </p>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Welcome;
