import styled from "styled-components";

export const Bigsection = styled.section`
  padding: 1rem 2rem;
  /* background-color: red; */
  width: 100%;
  .img {
    max-width: 70%;
    height: auto;
    overflow: hidden;
    border-radius: 1rem;
    cursor:pointer;
  }
`;
export const Img = styled.img``;
