import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Outlet, Link, useLocation, NavLink } from "react-router-dom";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { styled, useTheme } from "@mui/material/styles";
import { Avatar, Grid, Menu, Tooltip } from "@mui/material";
import { Mybutton, Links } from "./LeftSideBarStyle";
import { logout } from "../../Redux/AuthSlice/AuthSlice";
import { useDispatch } from "react-redux";
import Logo from "../../assets/elkotby-logo.png";
import CloseIcon from "@mui/icons-material/Close";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme?.transitions?.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    // backgroundColor:"black",
    ...(open && {
      transition: theme?.transitions?.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions?.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions?.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor:"black"
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme?.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const CustomDrawer = styled(Drawer)({
  width: 300,
  "& css-8je8zh-MuiTouchRipple-root": {
    [`&:active, &.Mui-focusVisible`]: {
      // boxShadow: "0px 0px 0px 8px var(--box-shadow)",
    },
    [`&.Mui-active`]: {
      // boxShadow: "0px 0px 0px 14px var(--box-shadow)",
    },
  },
});
const LeftSideBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Logout = () => {
    console.log("Clicked");
    dispatch(logout());
  };
  const location = useLocation();
  console.log("location", location.pathname === "/home/projects");
  let activeStyle = {
    textDecoration: "underline blue",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.75)",
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
              // style={{ color: "white" }}
              style={{ color: "#001c5b" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img style={{ width: "50%" }} src={Logo}></img>
            </Typography>
            {/* <Typography variant="h6" noWrap component="div" align="center">
              <img style={{width:"50%"}} src={Logo}></img>
            </Typography> */}
            <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ p: "1.5rem 2rem", borderRadius: "1rem" }}
                    alt="Remy Sharp"
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px", mr: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Mybutton onClick={Logout}>Logout</Mybutton>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            {/* <img style={{width:"50%"}} src={Logo}></img> */}
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                // <ChevronLeftIcon />
                <CloseIcon style={{ color: "#001c5b" }}></CloseIcon>
              ) : (
                <CloseIcon style={{ color: "#001c5b" }}></CloseIcon>
                // <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Links>
              <NavLink
                to="/home/projects"
                // style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <DashboardIcon className="icon" />
                    </ListItemIcon>
                    Projects
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </Links>
            <Links>
              <NavLink
                to="rejected-report"
                // style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ThumbDownIcon className="icon" />
                    </ListItemIcon>
                    Rejected Report
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </Links>
            {/* <Links>
              <NavLink
                to="Statistics"
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    Statistics
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </Links> */}
          </List>
          <Divider />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Outlet></Outlet>
        </Main>
      </Box>
    </div>
  );
};

export default LeftSideBar;
