import { CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import {
  ADD_PROJECT,
  Get_PROJECT,
  reset,
} from "../../Redux/ProjectSlice/ProjectSlice";
import { useFormik } from "formik";
import * as yup from "yup";

const AddProject = ({ handleClose }) => {
  const [data, setData] = React.useState();
  const dispatch = useDispatch();
  const handleChangetxt = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };
  const status = useSelector((state) => state.project.addStatus);

  console.log("status in add project", status);

  const validationSchema = yup.object({
    project_name: yup
      .string("Enter your Project name")
      // .min(6, "Project name should be at least 6 characters length")
      // .max(10, "Project name should be of  8 characters length")
      .required("Project name is required"),
    description: yup.string("Enter your description"),
    // .required("Description is required"),
  });
  const formik = useFormik({
    initialValues: {
      project_name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(ADD_PROJECT(values));
      await dispatch(reset());
      await handleClose();
      await dispatch(Get_PROJECT());
    },
  });
  return (
    <>
      <h3>AddProject</h3>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="project_name"
          label="Project name"
          value={formik.values.project_name}
          onChange={formik.handleChange}
          error={
            formik.touched.project_name && Boolean(formik.errors.project_name)
          }
          helperText={formik.touched.project_name && formik.errors.project_name}
        />
        <TextField
          sx={{
            mt: 2,
          }}
          fullWidth
          id="description"
          name="description"
          label="Description"
          type="text"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
        <PrimaryButton
          type="submit"
          style={{ width: "100%" }}
          endIcon={status === "loading" && <CircularProgress color="inherit" />}
        >
          Submit
        </PrimaryButton>
      </form>
    </>
  );
};

export default AddProject;
