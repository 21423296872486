import { Button } from "@mui/material";
import styled from "styled-components";

export const MyButton = styled(Button)`
  color: blue;
  border: 1px solid black;
`;
export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 0,
  p: 4,
  backgroundColor: "white",
};
export const Bigsection = styled.div`
  .header {
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: #3a86ff;
    &:after {
      position: absolute;
      background-color: red;
      content: "";
      height: 0.2rem;
      bottom: -0.5rem;
      width: 10rem;
      border-radius: 0.5rem;
      background: hsla(239, 100%, 67%, 1);
      background: linear-gradient(
        90deg,
        hsla(239, 100%, 67%, 1) 0%,
        hsla(187, 100%, 89%, 1) 100%
      );

      background: -moz-linear-gradient(
        90deg,
        hsla(239, 100%, 67%, 1) 0%,
        hsla(187, 100%, 89%, 1) 100%
      );

      background: -webkit-linear-gradient(
        90deg,
        hsla(239, 100%, 67%, 1) 0%,
        hsla(187, 100%, 89%, 1) 100%
      );

      filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#595CFF", endColorstr="#C6F8FF", GradientType=1 );
    }
  }
  .key {
    font-size: 1rem;
    font-weight: 500;
  }
  .value {
    font-size: 1rem;
    color: #b0b0b0;
    font-weight: 500;
  }
  .button-64 {
    align-items: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    .text {
      background-color: rgb(5, 6, 45);
      padding: 16px 24px;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      transition: 300ms;
    }
    &:hover {
      outline: 0;
    }
    &:hover span {
      background: none;
    }
  }
  .invite {
    font-size: 25px;
    background: #1525f5;
    background: linear-gradient(to right, #1525f5 0%, #1584cf 91%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .user {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.275);
    border-radius: 1rem;
    margin-right: 0.9rem;
    margin-bottom: 0.9rem;
    position: relative;
  }
  .content {
    padding: 1rem 0;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .remove {
    position: absolute;
    top: 0;
    right: 0;
    button {
      background: none;
      border: none;
      cursor: pointer;
      color: red;
    }
  }
`;
export const ThisButton = styled(Button)`
  && {
    align-items: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    &:hover &:active {
      outline: 0;
    }
    span {
      background-color: rgb(5, 6, 45);
      padding: 16px 24px;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      transition: 300ms;
    }
  }
`;
export const Invite = styled.p`
  font-size: 25px;
  background: #1525f5;
  background: linear-gradient(to right, #1525f5 0%, #1584cf 91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Direct = styled.span`
  color: #0253b8;
`;
