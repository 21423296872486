import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import React from "react";
import { BigSection } from "../../Pages/ContainerWasher/ContainerWasherStyle";
import { useFormik } from "formik";
import { Grid, TextField, FormControl, MenuItem, CircularProgress } from "@mui/material";
import {
  CreateContainer,
  Edit_Container,
  Get_Containers,
  GET_WashingTypes,
} from "../../Redux/Containers/ContainersSlice";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

const EditContainerForm = ({ close, container }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(GET_WashingTypes());
  }, []);
  const newRows = useSelector((state) => state.containers.all_containers) || [];
  const status = useSelector((state) => state?.containers.create_status);
  const created_container = useSelector(
    (state) => state?.containers.created_container
  );
  const statusGetCont = useSelector((state) => state?.containers.status);

  const navigate = useNavigate();

  const washingTypes =
    useSelector((state) => state?.containers?.washing_types) || [];

  const [date, setDate] = React.useState({
    wash_date: new Date().toISOString().split("T")[0],
  });
  let data = {
    id: id,
    page: 1,
  };
  const validationSchema = yup.object({
    location: yup.string("Enter your Location"),
    // .min(14, "The location must be 14 chars")
    // .max(14, "The Location must be 14 chars")
    // .required("Location is required"),
    number: yup
      .string("Enter your number")
      .min(11, "number must be 11 chars")
      .max(11, "number must be 11 chars")
      .required("Number is required"),
    // start_time: yup
    //   .string("Enter your Start time")
    //   .required("Start time is required"),
    // end_time: yup
    //   .string("Enter your End time")
    //   .required("End time is required"),
    // wash_date: yup.date("Must Be a date").required("Wash date is required"),
    washing_type: yup.string("Enter your washing_type"),
    // .required("washing Type is required"),
  });
  let sendDate = {
    wash_date: new Date(date.wash_date).toLocaleDateString("en-GB"),
  };
  const [time, setTime] = React.useState({
    start_time: "",
    end_time: "",
  });
  let sendTime = {
    start_time: time.start_time.replace(":00", ""),
    end_time: time.end_time.replace(":00", ""),
  };
  console.log("sendTimesendTime", sendTime);
  console.log("container", container);
  console.log("Time", time);
  const formik = useFormik({
    initialValues: {
      location: "",
      number: "",
      project_id: id,
      // start_time: new Date(date.wash_date).toLocaleTimeString("en-US"),
      // end_time: new Date(date.wash_date).toLocaleTimeString("en-US"),
      washing_type: "",
      // wash_date: ,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(
        Edit_Container({ ...values, ...sendDate, ...sendTime, container })
      );
      await dispatch(Get_Containers(data));
      await close();
    },
  });
  React.useEffect(() => {
    formik.values.number = container.number;
    formik.values.location = container.location;
    formik.values.washing_type = container.washing_type?.id;
    date.wash_date = container.wash_date;
    time.start_time = container.start_time;
    time.end_time = container.end_time;
  }, [container]);
  return (
    <>
      <BigSection>
        <Grid
          container
          columns={12}
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {/* Left Side */}
          <Grid item lg={12} md={12} sm={12}>
            <form onSubmit={formik.handleSubmit}>
              <div className="uploadimage">
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="location"
                      type="text"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        label="Washing Type"
                        name="washing_type"
                        value={formik.values.washing_type}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.washing_type &&
                          Boolean(formik.errors.washing_type)
                        }
                        helperText={
                          formik.touched.washing_type &&
                          formik.errors.washing_type
                        }
                      >
                        {washingTypes?.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="outlined-basic"
                      variant="outlined"
                      label="number"
                      type="text"
                      name="number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                    />
                  </Grid>
                  <Grid item lg={6} md={6}>
                    {/* <FormControl fullWidth> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Wash Date"
                          value={formik.values.wash_date}
                          name="wash_date"
                          onChange={(newDate) => {
                            formik.setFieldValue(
                              "wash_date",
                              newDate.toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })
                            );
                          }}
                          error={
                            formik.touched.wash_date &&
                            Boolean(formik.errors.wash_date)
                          }
                          helperText={
                            formik.touched.wash_date && formik.errors.wash_date
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider> */}
                    <TextField
                      fullWidth
                      type="date"
                      required
                      value={date.wash_date}
                      name="wash_date"
                      label="Wash Date"
                      // initialValues={date.wash_date}
                      onChange={(newDate) => {
                        setDate({
                          ...date,
                          wash_date: newDate.target.value,
                        });
                      }}
                      // error={
                      //   formik.touched.wash_date &&
                      //   Boolean(formik.errors.wash_date)
                      // }
                      // helperText={
                      //   formik.touched.wash_date && formik.errors.wash_date
                      // }
                    ></TextField>
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item lg={6} md={6}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Start Time"
                        value={data?.start_time}
                        onChange={(newValue) => {
                          setData({
                            ...data,
                            start_time: newValue.toLocaleTimeString("en-GB"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider> */}
                    <TextField
                      fullWidth
                      type="time"
                      // value={formik.values.start_time}
                      // onChange={formik.handleChange}
                      value={time.start_time}
                      onChange={(e) => {
                        setTime({ ...time, start_time: e.target.value });
                      }}
                      label="Start Time"
                      name="start_time"
                      // error={
                      //   formik.touched.start_time &&
                      //   Boolean(formik.errors.start_time)
                      // }
                      // helperText={
                      //   formik.touched.start_time && formik.errors.start_time
                      // }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="End Time"
                        value={data?.end_time}
                        onChange={(newValue) => {
                          setData({
                            ...data,
                            end_time: newValue.toLocaleTimeString("en-GB"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider> */}
                    <TextField
                      fullWidth
                      type="time"
                      name="end_time"
                      label="End Time"
                      // value={formik.values.end_time}
                      // onChange={formik.handleChange}
                      value={time.end_time}
                      onChange={(e) => {
                        setTime({ ...time, end_time: e.target.value });
                      }}
                      // error={
                      //   formik.touched.end_time &&
                      //   Boolean(formik.errors.end_time)
                      // }
                      // helperText={
                      //   formik.touched.end_time && formik.errors.end_time
                      // }
                    ></TextField>
                  </Grid>
                </Grid>

                <hr />
              </div>
              <Grid item lg={12} md={12}>
                <PrimaryButton
                  style={{ width: "100%" }}
                  type="submit"
                  endIcon={
                    statusGetCont === "loading" && <CircularProgress color="inherit" />
                  }
                >
                  Submit
                </PrimaryButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </BigSection>
    </>
  );
};

export default React.memo(EditContainerForm);
