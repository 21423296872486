import styled from "styled-components";

export const BigSection = styled.div``;

export const Div = styled.div`
  height:10rem ;
  justify-items: center;
  transition: all 0.5s;
  border-radius: 1rem;
  background-color: #004071;
  /* background-color: ${(props) => (props.red ? "red" : "#004071")}; */
  background-image: linear-gradient(160deg, #004071 0%, #80d0c7 100%);
  /* background-image: ${(props) =>
    props.red
      ? "linear-gradient(160deg, red 0%, #80d0c7 100%)"
      : "linear-gradient(160deg, #004071 0%, #80d0c7 100%)"}; */
  /* min-height: 15rem; */

  position: relative;
  .header {
    padding: 2rem;
    font-size: 1rem;
    color: white;
    p {
      text-align: right;
    }
  }
  &:hover {
    transform: translateY(-0.5rem);
  }
`;
