import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { add_project, download, edit_project, statistics } from "../../Api/Api";
import {
  GET_ALL_PROJECTS_Table,
  get_single_project,
  invite_uset,
  delete_user,
} from "../../Api/Api";
import FileDownload from "js-file-download";
// Get the Data From Local Storage

const user = JSON.parse(localStorage.getItem("crew"));
//  const role = isAutherized?.user?.type|| isAutherized?.data?.user?.type;
// Header for Apis

export const ADD_PROJECT = createAsyncThunk(
  "project/create",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "POST",
        baseURL: `${add_project}`,
        headers: headers,
        data: data,
      }).then((res) => {
        console.log("Response ", res.data.data);
        toast.success(`Project Added`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error?.response?.data?.message;
      console.log("ADD_PROJECT Error", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_PROJECT = createAsyncThunk(
  "project/Get",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${GET_ALL_PROJECTS_Table}`,
        headers: headers,
        params: { page: data },
      }).then((res) => {
        console.log("Response ", res.data.data);
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();

      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_Single_PROJECT = createAsyncThunk(
  "SingleProject/Get",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${get_single_project}/${data}`,
        headers: headers,
      }).then((res) => {
        console.log("Response Single Project ", res.data.data);
        return res.data.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();

      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Invite_User = createAsyncThunk(
  "User/Invite",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "POST",
        baseURL: `${invite_uset}`,
        headers: headers,
        data: data,
      }).then((res) => {
        console.log("Response ", res.data.data);
        toast.success(`Invitaion Has been sent`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Delete_User = createAsyncThunk(
  "User/Delete",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "DELETE",
        baseURL: `${delete_user}/${data}`,
        headers: headers,
        data: data,
      }).then((res) => {
        console.log("Response ", res.data.data);
        toast.success(`‘User Has been Deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_statistics = createAsyncThunk(
  "statistics/Get",
  async (_, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${statistics}`,
        headers: headers,
      }).then((res) => {
        console.log("Response ", res.data.data);
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Download_Report = createAsyncThunk(
  "report/Post",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/pdf",
        // "content-disposition":"content-disposition"
      };
      return await axios({
        method: "POST",
        baseURL: `${download}`,
        headers: headers,
        data: data,
        // responseType: 'arraybuffer',
        responseType: "blob",
        params: {
          type: "excel",
        },
      }).then((res) => {
        const [, filename] =
          res.headers["content-disposition"].split("filename=");
        let modifyFile = filename.replace(/"([^"]+(?="))"/g, "$1");
        // console.log("filenamefilenamefilenamefilename",filename)
        // console.log("sdfghjklkjhgf")
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("download", filename); //or any other extension
        link.download = modifyFile;
        document.body.appendChild(link);
        link.click();
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Edit_Project = createAsyncThunk(
  "Project/Edit",
  async (data, thunkAPI) => {
    console.log("datadatadatadata", data);
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "PATCH",
        baseURL: `${edit_project}/${data.id}/update`,
        // baseURL: `${edit_project}/24/update`,

        headers: headers,
        data: data.data,
      }).then((res) => {
        toast.success(`Project Edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error?.response?.data?.message;
      console.log("Text in a modal", message);
      let allMessage;
      // for (const property in message) {
      //   console.log(`${property}: ${message[property]}`);
      //   allMessage = `${property}: ${message[property]}`;
      // }
      // console.log("Upload_multi_images Error", allMessage);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: ` ${message}`,
        // })}
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const project = createSlice({
  name: "project",
  initialState: {
    all_prjects: "",
    token: "",
    isAuth: false,
    status: "",
    error: "",
    addStatus: "",
    getProjectStatus: "",
    singleProject: "",
    try: "",
    statistics: "",
    report: "",
  },
  reducers: {
    reset: (state) => {
      state.error = "";
      state.addStatus = "";
      state.status = "";
      state.isSingleSuccess = false;
      state.massage = "";
    },
  },
  extraReducers: {
    [ADD_PROJECT.pending]: (state, { payload }) => {
      state.addStatus = "loading";
    },
    [ADD_PROJECT.fulfilled]: (state, { payload }) => {
      state.addStatus = "success";
      state.data = payload;
      state.isAuth = true;
    },
    [ADD_PROJECT.rejected]: (state, { payload }) => {
      state.addStatus = "failed";
      state.error = payload;
    },
    [Get_PROJECT.pending]: (state, { payload }) => {
      state.getProjectStatus = "loading";
    },
    [Get_PROJECT.fulfilled]: (state, { payload }) => {
      state.getProjectStatus = "success";
      state.all_prjects = payload;
    },
    [Get_PROJECT.rejected]: (state, { payload }) => {
      state.getProjectStatus = "failed";
      state.error = payload;
    },
    [Get_Single_PROJECT.pending]: (state, { payload }) => {
      state.getProjectStatus = "loading";
    },
    [Get_Single_PROJECT.fulfilled]: (state, { payload }) => {
      state.getProjectStatus = "success";
      console.log("Paylaod", payload);
      state.singleProject = payload;
    },
    [Get_Single_PROJECT.rejected]: (state, { payload }) => {
      state.getProjectStatus = "failed";
      state.error = payload;
    },
    [Invite_User.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Invite_User.fulfilled]: (state, { payload }) => {
      state.status = "success";
    },
    [Invite_User.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Delete_User.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Delete_User.fulfilled]: (state, { payload }) => {
      state.status = "success";
    },
    [Delete_User.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Get_statistics.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Get_statistics.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.statistics = payload;
    },
    [Get_statistics.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Download_Report.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Download_Report.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.report = payload;
    },
    [Download_Report.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Edit_Project.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Edit_Project.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.report = payload;
    },
    [Edit_Project.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export const reset = project.actions.reset;

export default project.reducer;
