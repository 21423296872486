import styled from "styled-components";

export const Div = styled.div`
  .containerInfo {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;
    text-align: center;
    border-radius: 1rem;
  }
  p{
    font-weight: 600;
    span{
      color: #666666;
    }
  }
`;
