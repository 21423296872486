import { Grid } from "@mui/material";
import React from "react";
import { Bigsection, Img } from "./ShowImagesStyle";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
const ShowImages = ({ images }) => {
  return (
    <Bigsection>
      <Grid container columns={12}>
        {images?.position_1?.before?.media_url ||
        images?.position_1?.after?.media_url ? (
          <>
            <Grid lg={12} md={12} sm={12}>
              <h3>First Photo</h3>
              <hr></hr>
            </Grid>
          </>
        ) : null}
        {images?.position_1?.before?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>before</p>
              <Gallery>
                <Item
                  original={images?.position_1?.before?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_1?.before?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
              {/* <Img
                alt="not fount"
                width={"250px"}
                src={images?.position_1?.before?.media_url?.thumbnail}
              /> */}
            </Grid>
          </>
        )}
        {images?.position_1?.after?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>after</p>
              <Gallery>
                <Item
                  original={images?.position_1?.after?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_1?.after?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            </Grid>
          </>
        )}
        {images?.position_2?.before?.media_url ||
        images?.position_2?.after?.media_url ? (
          <>
            <Grid lg={12} md={12} sm={12}>
              <h3>Second Photo</h3>
              <hr></hr>
            </Grid>
          </>
        ) : null}
        {images?.position_2?.before?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>before</p>
              <Gallery>
                <Item
                  original={images?.position_2?.before?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_2?.before?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            </Grid>
          </>
        )}
        {images?.position_2?.after?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>after</p>
              <Gallery>
                <Item
                  original={images?.position_2?.after?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_2?.after?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            </Grid>
          </>
        )}
        {images?.position_3?.before?.media_url ||
        images?.position_3?.after?.media_url ? (
          <>
            <Grid lg={12} md={12} sm={12}>
              <h3>Third Photo</h3>
              <hr></hr>
            </Grid>
          </>
        ) : null}
        {images?.position_3?.before?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>before</p>
              <Gallery>
                <Item
                  original={images?.position_3?.before?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_3?.before?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            </Grid>
          </>
        )}
        {images?.position_3?.after?.media_url && (
          <>
            <Grid item lg={6} md={12} sm={12}>
              <p>after</p>
              <Gallery>
                <Item
                  original={images?.position_3?.after?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      className="img"
                      ref={ref}
                      onClick={open}
                      src={images?.position_3?.after?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            </Grid>
          </>
        )}
      </Grid>
    </Bigsection>
  );
};

export default ShowImages;
