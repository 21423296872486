import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete_User,
  Get_Single_PROJECT,
  Invite_User,
} from "../../Redux/ProjectSlice/ProjectSlice";
import { Bigsection, Style, ThisButton, Invite } from "./TableStyle";
import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import { AddModeratorSharp } from "@mui/icons-material";
const SinlgeProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (data) => {
    setOpenDelete(true);
    // setSelectedUser(data);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  React.useEffect(() => {
    dispatch(Get_Single_PROJECT(id));
  }, []);
  const allData = useSelector((state) => state?.project?.singleProject);
  const status = useSelector((state) => state.project?.status);
  const [data, setData] = React.useState({
    project_id: id,
    name: "",
    email: "",
  });
  const [selectedUser, setSelectedUser] = React.useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(Invite_User(data));
    await handleClose();
    await dispatch(Get_Single_PROJECT(id));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  console.log("selectedUser", selectedUser);
  const deleteUser = async () => {
    await dispatch(Delete_User(selectedUser.id));
    await dispatch(Get_Single_PROJECT(id));
    await handleCloseDelete();
  };
  return (
    <Bigsection>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
      >
        <Grid item lg={6} md={6}>
          <button className="button-64" onClick={handleOpen}>
            <span className="text">Invite User</span>
          </button>
        </Grid>
        <Grid item lg={6} md={6}>
          <button className="button-64">
            <span className="text">Nontify customer</span>
          </button>
        </Grid>
        <Grid container item lg={6} md={6}>
          <Grid item lg={12} md={12} sm={12}>
            <div className="header">
              <p>Project Info</p>
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <p className="key">
              Project name :{" "}
              <span className="value">{allData.project_name}</span>
            </p>
            <p className="key">
              Description : <span className="value">{allData.description}</span>
            </p>
            <p className="key">
              Created Date :
              <span className="value">
                {new Date(allData.created_at).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </span>
            </p>
          </Grid>
        </Grid>
        <Grid container item lg={6} md={6}>
          <Grid item lg={12} md={12} sm={12}>
            {" "}
            <div className="header">
              <p>Users Info</p>
            </div>
          </Grid>

          {allData.users?.length > 0 ? (
            <>
              {allData?.users.map((e, i) => {
                return (
                  <>
                    {/* <Grid container columns={12}> */}
                    <Grid item lg={6} md={6} sm={12}>
                      <div className="user">
                        <div className="content">
                          <h3>User: {++i}</h3>
                          <p className="key">
                            Name : <span className="value">{e.name}</span>
                          </p>
                          <p className="key">
                            Email : <span className="value">{e.email}</span>
                          </p>
                          <p className="key">
                            Status :{" "}
                            <span className="value">
                              {e.email_verified_at ? <>Active</> : <>Pending</>}
                            </span>
                          </p>
                        </div>
                        <div className="remove">
                          <button
                            onClick={() => {
                              handleOpenDelete();
                              setSelectedUser(e);
                            }}
                          >
                            Delete user
                          </button>
                        </div>
                      </div>
                    </Grid>{" "}
                    {/* </Grid> */}
                  </>
                );
              })}
            </>
          ) : (
            <h3>No users for this Project</h3>
          )}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <Invite>Invite a user</Invite>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                type="text"
                required
                name="name"
                onChange={handleChange}
              />
              <div style={{ padding: ".5rem 0" }}></div>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                required
                onChange={handleChange}
              />
              <PrimaryButton
                endIcon={
                  status === "loading" ? (
                    <CircularProgress size={20} thickness={8} />
                  ) : null
                }
                type="submit"
                fullWidth
              >
                Submit
              </PrimaryButton>
            </form>
          </Typography>
        </Box>
      </Modal>{" "}
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <Invite>Are you sure you want to Delete The user</Invite>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <Button style={{ color: "red" }} onClick={deleteUser}>
              Yes
            </Button>
            <Button onClick={handleCloseDelete}>No</Button>
          </Typography>
        </Box>
      </Modal>{" "}
    </Bigsection>
  );
};

export default SinlgeProject;
