import { Button } from "@mui/material";
import styled from "styled-components";

export const MyButton = styled(Button)`
  && {
    background: transparent;
    color: white !important;
    background-color: #1f87dc;
    margin: 0 0.3rem;
    text-align: center;
  }
`;
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
