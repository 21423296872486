import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
// import { add_project } from "../../Api/Api";
import {
  admin_containers_photos,
  admin_get_media,
  edit_container,
  GET_ALL_PROJECTS_Table,
  multi_image,
  search_container,
  User_Container_Filteration,
} from "../../Api/Api";
import {
  wash_types,
  add_container,
  Get_containers,
  containers_photos,
  User_Container,
  user_get_media,
  change_status,
  delete_container,
} from "../../Api/Api";
// Get the Data From Local Storage

const user = JSON.parse(localStorage.getItem("crew"));
const token = user?.token;

export const GET_WashingTypes = createAsyncThunk(
  "project/GET",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${wash_types}`,
        headers: headers,
      }).then((res) => {
        console.log("Response ", res.data.data);
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreateContainer = createAsyncThunk(
  "container/Post",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "Post",
        baseURL: `${add_container}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Container Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_Containers = createAsyncThunk(
  "container/Get",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "Get",
        baseURL: `${Get_containers}/${data.id}/paginate`,
        headers: headers,
        params: {
          page: data.page,
        },
      }).then((res) => {
        console.log("Form Response,", res.data.data);
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Add_Image_Containers = createAsyncThunk(
  "container/photos",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "POST",
        baseURL: `${containers_photos}/${data.id}/media`,
        headers: headers,
        data: data.form,
      }).then((res) => {
        toast.success(`Image Added`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error?.response?.data?.message?.image?.toString();
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_user_conainers = createAsyncThunk(
  "user/GetAllcontainers",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${User_Container}`,
        headers: headers,
        params: {
          page: data.page,
        },
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_user_conainers_photos = createAsyncThunk(
  "user/GetcontainersPhotos",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${user_get_media}/${data}/media`,
        headers: headers,
        data: data.form,
      }).then((res) => {
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_admin_conainers_photos = createAsyncThunk(
  "admin/GetcontainersPhotos",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${admin_get_media}/${data}/media`,
        headers: headers,
        data: data.form,
      }).then((res) => {
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Change_Status = createAsyncThunk(
  "user/ChangeStatus",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "POST",
        baseURL: `${change_status}/${data.id}/change-status`,
        headers: headers,
        data: data.status,
      }).then((res) => {
        console.log("Response ", res.data.data);
        toast.success(`Status Updated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeleteContaienr = createAsyncThunk(
  "Container/Delete",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "DELETE",
        baseURL: `${delete_container}/${data}`,
        headers: headers,
      }).then((res) => {
        toast.success(`Container has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Get_user_conainers_Filteration = createAsyncThunk(
  "user/GetAllcontainersFilteration",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "POST",
        baseURL: `${User_Container_Filteration}`,
        headers: headers,
        params: {
          page: data.page,
          from: data.from,
          to:data.to,
          search:data.search
        },
        data:data,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Search_Container = createAsyncThunk(
  "user/GetContainerSearch",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "GET",
        baseURL: `${search_container}`,
        headers: headers,
        params: {
          search: data.search,
          project_id: data.id,
        },
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.massage) ||
        error.response.data.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Upload_multi_images = createAsyncThunk(
  "multiImages/upload",
  async (data, thunkAPI) => {
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      console.log("data check", data);
      return await axios({
        method: "POST",
        baseURL: `${multi_image}/${data.id}/media-array`,
        headers: headers,
        data: data.form,
      }).then((res) => {
        toast.success(`Images Uploaded`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data.data;
      });
    } catch (error) {
      const message = error?.response?.data?.message;
      let allMessage;
      for (const property in message) {
        console.log(`${property}: ${message[property]}`);
        allMessage = `${property}: ${message[property]}`;
      }
      console.log("Upload_multi_images Error", allMessage);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: ` ${Object.keys(message).map((key, index) => {
          return `${key}: ${message[key]}`;
        })}`,
      });
      // toast.error(`${message?.toString()}`, {
      //   position: "top-right",
      //   autoClose: 10000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Edit_Container = createAsyncThunk(
  "container/edit",
  async (data, thunkAPI) => {
    console.log("EDit data send", data);
    try {
      const tryToken =
        thunkAPI.getState().auth?.data?.data?.token || user?.token;
      const headers = {
        Authorization: `Bearer ${tryToken}`,
        Accept: "application/json",
      };
      return await axios({
        method: "PATCH",
        baseURL: `${edit_container}/${data.container.id}/update`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Container Edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data.data;
      });
    } catch (error) {
      const message = error?.response?.data?.message;
      // let allMessage;
      // for (const property in message) {
      //   console.log(`${property}: ${message[property]}`);
      //   allMessage = `${property}: ${message[property]}`;
      // }
      // console.log("Upload_multi_images Error", allMessage);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message} `,
        // })},
      });
      // toast.error(`${message?.toString()}`, {
      //   position: "top-right",
      //   autoClose: 10000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const containers = createSlice({
  name: "Container",
  initialState: {
    all_containers: [],
    status: "",
    error: "",
    washing_types: "",
    created_container: "",
    create_status: "",
    add_image_status: "",
    userContainers: [],
    userContainerPhotos: "",
    mix_containers: [],
    images: "",
    editContainer: "",
  },
  reducers: {},
  extraReducers: {
    [GET_WashingTypes.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GET_WashingTypes.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.washing_types = payload.data;
    },
    [GET_WashingTypes.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Get_Containers.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Get_Containers.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.all_containers = payload.data;
    },

    [Get_Containers.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [CreateContainer.pending]: (state, { payload }) => {
      state.create_status = "loading";
    },
    [CreateContainer.fulfilled]: (state, { payload }) => {
      state.create_status = "success";
      state.created_container = payload.data;
    },
    [CreateContainer.rejected]: (state, { payload }) => {
      state.create_status = "failed";
      state.error = payload;
    },

    [Add_Image_Containers.pending]: (state, { payload }) => {
      state.add_image_status = "loading";
    },
    [Add_Image_Containers.fulfilled]: (state, { payload }) => {
      state.add_image_status = "success";
    },
    [Add_Image_Containers.rejected]: (state, { payload }) => {
      state.add_image_status = "failed";
    },
    [Get_user_conainers.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Get_user_conainers.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userContainers = payload;
    },
    [Get_user_conainers.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Get_user_conainers_Filteration.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Get_user_conainers_Filteration.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userContainers = payload;
    },
    [Get_user_conainers_Filteration.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },

    [get_user_conainers_photos.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [get_user_conainers_photos.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userContainerPhotos = payload.data;
    },
    [get_user_conainers_photos.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Change_Status.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Change_Status.fulfilled]: (state, { payload }) => {
      state.status = "success";
    },
    [Change_Status.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [get_admin_conainers_photos.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [get_admin_conainers_photos.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userContainerPhotos = payload.data;
    },
    [get_admin_conainers_photos.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [DeleteContaienr.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [DeleteContaienr.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.delete_container = payload.data;
    },
    [DeleteContaienr.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Search_Container.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Search_Container.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.all_containers = payload.data;
    },

    [Search_Container.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Upload_multi_images.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Upload_multi_images.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userContainersPhotos = payload.data;
    },

    [Upload_multi_images.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Edit_Container.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [Edit_Container.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.editContainer = payload.data;
    },

    [Edit_Container.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});

export default containers.reducer;
