const mainUrl = "https://washing.elkotby.com/api";
export const Login = `${mainUrl}/login`;

export const add_project = `${mainUrl}/admin/project`;

export const add_container = `${mainUrl}/admin/containers`;

export const Logot = `${mainUrl}/login`;

export const GET_ALL_PROJECTS = `${mainUrl}/admin/project/all`;

export const GET_ALL_PROJECTS_Table = `${mainUrl}/admin/project/all/paginate`;

export const wash_types = `${mainUrl}/admin/washing-types/all`;

export const Get_project = `${mainUrl}/admin/containers/project`;

export const Get_containers = `${mainUrl}/admin/containers/project`;

export const containers_photos = `${mainUrl}/admin/containers`;

export const user_containers_photos = `${mainUrl}/admin/containers`;

export const User_Container = `${mainUrl}/customer/containers/all/paginate`;

export const user_get_media = `${mainUrl}/customer/containers`;

export const admin_get_media = `${mainUrl}/admin/containers`;

export const change_status = `${mainUrl}/customer/containers`;

export const get_single_project = `${mainUrl}/admin/project`;

export const invite_uset = `${mainUrl}/invite`;

export const delete_user = `${mainUrl}/user`;

export const delete_container = `${mainUrl}/admin/containers`;

export const statistics = `${mainUrl}/customer/statistics`;

export const User_Container_Filteration = `${mainUrl}/customer/containers/all/paginate`;

export const search_container = `${mainUrl}/admin/containers/all/paginate`;

export const download = `${mainUrl}/customer/containers/download-report`;

export const multi_image = `${mainUrl}/admin/containers`;

export const edit_container = `${mainUrl}/admin/containers`;

export const edit_project = `${mainUrl}/admin/project`;
