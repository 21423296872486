import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { set } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PrimaryButton } from "../../Layouts/ButtonsControlled";
import {
  Change_Status,
  get_user_conainers_photos,
} from "../../Redux/Containers/ContainersSlice";
import { BigSection } from "./ShowPhotosStyle";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import Lottie from "lottie-react";
import loader from "../../Components/Loader/9616-animation-loader.json";

const ShowPhotos = ({ container }) => {
  console.log("Rendered")
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [data, setData] = React.useState({
    status: container?.status,
  });
  const { userContainerPhotos } = useSelector((state) => state?.containers);
  const { id } = useParams();
  const navigate = useNavigate();
  const status = useSelector((state) => state.containers.status);
  console.log("statusstatus", status);

  let Alldata = {
    id: container.id,
    status: data,
  };
  console.log("container infop", container.id);
  React.useEffect(() => {
    dispatch(get_user_conainers_photos(container.id));
  }, []);

  console.log("state", state);
  const arr = [
    { name: "approved", value: "approved" },
    { name: "rejected", value: "rejected" },
    { name: "pending", value: "pending" },
  ];

  React.useEffect(() => {
    setData({ ...data, status: container?.status });
  }, [state]);

  const handlSubmit = async (e) => {
    await e.preventDefault();
    await dispatch(Change_Status(Alldata));
    await navigate("/home/user-container");
  };

  const isEmpty = Object?.keys(userContainerPhotos);
  return (
    <BigSection>
      <>
        {status !== "loading" && (
          <>
            <Grid
              container
              columns={12}
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={5}
            >
              {/* <Grid item lg={12}>
            <Link to="/" state={{ number: state.number }}>
              <Button>Go Back</Button>
            </Link>
          </Grid> */}
              <Grid item lg={6}>
                <h3>Container info</h3>
                <Grid container columns={12}>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      Container Number: <span>{container.number}</span>
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      Container Location: <span>{container.location}</span>
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      Wash Date :{" "}
                      <span>
                        {new Date(container?.wash_date).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </span>
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      Start Time : <span>{container?.start_time}</span>
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      End Time : <span> {container?.end_time}</span>
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    {" "}
                    <p>
                      Status : <span> {container?.status}</span>
                    </p>
                  </Grid>
                  {isEmpty.length > 0 ? (
                    <>
                      <Grid item lg={12}>
                        {" "}
                        <form onSubmit={handlSubmit}>
                          <FormControl fullWidth>
                            <TextField
                              select
                              label="Container approvement"
                              required
                              name="washing_type"
                              value={data?.status}
                              onChange={(e) => {
                                setData({ ...data, status: e.target.value });
                              }}
                            >
                              {arr?.map((e) => (
                                <MenuItem key={e.value} value={e.value}>
                                  {e.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                          <Grid item lg={12}>
                            <PrimaryButton
                              style={{ width: "100%" }}
                              type="submit"
                              endIcon={
                                status === "loading" ? (
                                  <CircularProgress size={20} thickness={8} />
                                ) : null
                              }
                            >
                              Submit
                            </PrimaryButton>
                          </Grid>
                        </form>
                      </Grid>
                    </>
                  ) : (
                    <>{null}</>
                  )}
                </Grid>
              </Grid>
              {isEmpty.length > 0 || isEmpty.length !== 0 ? (
                <>
                  <Grid item lg={6}>
                    <Grid container columns={12}>
                      <Grid item lg={12}>
                        {" "}
                        <h3>First Photo</h3>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>Before</h4>
                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_1?.before?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_1?.before
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>After</h4>
                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_1?.after?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_1?.after
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                    </Grid>
                    <Grid container columns={12}>
                      <Grid item lg={12}>
                        {" "}
                        <h3>Second Photo</h3>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>Before</h4>
                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_2?.before?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_2?.before
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>After</h4>

                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_2?.after?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_2?.after
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                    </Grid>
                    <Grid container columns={12}>
                      <Grid item lg={12}>
                        {" "}
                        <h3>Third Photo</h3>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>Before</h4>

                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_3?.before?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_3?.before
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <h4>After</h4>

                        <Gallery>
                          <Item
                            original={
                              userContainerPhotos?.position_3?.after?.media_url
                                ?.display
                            }
                            width="1024"
                            height="768"
                          >
                            {({ ref, open }) => (
                              <img
                                className="img"
                                ref={ref}
                                onClick={open}
                                src={
                                  userContainerPhotos?.position_3?.after
                                    ?.media_url?.thumbnail
                                }
                              />
                            )}
                          </Item>
                        </Gallery>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={6}>
                    <h3 style={{ textAlign: "center" }}>No Photos</h3>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        {status === "loading" && (
          <>
            <Lottie
              style={{
                width: "200px",
                height: "200px",
                position: "absolute",
                transform: "translate(-50%, -50%)",
                left: "50%",
                top: "50%",
              }}
              loop={true}
              animationData={loader}
            />
          </>
        )}
      </>
    </BigSection>
  );
};

export default React.memo(ShowPhotos);
