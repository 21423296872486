import styled from "styled-components";
import { TextField } from "@mui/material";
export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3120E0",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3120E0",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "blue",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "blue",
    },
  },
});
export const Slogan = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Beau+Rivage&display=swap");
  font-family: "Beau Rivage", cursive;
  color: #00308f;
  font-size: 2rem;
  text-align: center;
  font-weight: 800;
  letter-spacing: 0.7rem;
`;
